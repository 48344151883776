var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-wrapper full-page" }, [
      _c(
        "div",
        {
          staticClass:
            "page-content d-flex align-items-center justify-content-center"
        },
        [
          _c("div", { staticClass: "row w-100 mx-0 auth-page" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-md-8 col-xl-6 mx-auto d-flex flex-column align-items-center"
              },
              [
                _c("img", {
                  staticClass: "img-fluid mb-2",
                  attrs: { src: "/assets/images/404.svg", alt: "404" }
                }),
                _c(
                  "h1",
                  {
                    staticClass: "font-weight-bold mb-22 mt-2 tx-80 text-muted"
                  },
                  [_vm._v("404")]
                ),
                _c("h4", { staticClass: "mb-2" }, [_vm._v("Page Not Found")]),
                _c("h6", { staticClass: "text-muted mb-3 text-center" }, [
                  _vm._v("Oopps!! The page you were looking for doesn't exist.")
                ])
              ]
            )
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }